.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background: var(--body-color);
  padding-top: var(--mb-1-5);
}
.nav {
  height: calc(var(--header-height)+1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}
.logo {
  width: 100px;
}

.lang-icon {
  width: 30px;
  border-radius: 15px;
  border: 2px solid var(--border-color);
}

.lang-icon:hover {
  animation: vibrate-1 1s linear infinite both;
}
.nav__list {
  display: flex;
  column-gap: 2rem;
}
.nav__link {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}
.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

/*Active Link*/

.active-lik,
.nav__link:hover {
  color: var(--title-color-dark);
}
/*aux*/
.icons-links {
  font-size: 1.5rem;
}
/*===BreakingPoints===*/

/*for medium devices*/

@media (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
  }
  .nav {
    height: var(--header-height);
  }
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px 10px 0 0;
    transition: 0.5s;
  }
  /*show menu*/

  .show-menu {
    bottom: 0;
  }
  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 3rem;
  }
  .nav__icon {
    font-size: 1.2rem;
  }
  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }
  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }
}
@media (min-width: 762px) {
  .icons-links {
    display: none;
  }
}
@media (max-width: 450px) {
  .nav__link {
    align-items: flex-start;
  }
}
/*for small devices*/
@media (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav__list {
    column-gap: 0;
  }
}
@media (max-width: 250px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

/*animations*/

@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
