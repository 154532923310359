.home {
  margin-top: 8rem;
}
.home__container {
  display: flex;
  flex-direction: column;
}
.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}
.home__data {
  display: flex;
  flex-direction: column;
}
.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}
.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}
.home__social-icon-img {
  width: 15px;
  height: 15px;
  filter: invert(0.5);
  margin-left: 0.1em;
}
.home__social-icon:hover,
.home__social-icon-img :hover {
  filter: contrast(0);
}
.home__img {
  background: url(../../assets/images/luis_rondon.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  justify-self: center;
  order: 1;
  width: 235px;
  height: 310px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}
.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
  line-height: 60px;
}
.home__subtitle {
  position: relative;
  margin-left: 8.5rem;
}
.home__subtitle::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 2px;
  background-color: var(--text-color);
  left: -8.5rem;
  top: 0.75rem;
}
.home__description {
  margin: 0.5em 0 2.5em 0;
}
.home__down-icon {
  font-size: 3.25rem;
  color: var(--title-color);
}
.button--flex {
  display: flex;
  justify-content: space-evenly;
  max-width: 200px;
}
.button--flex-up {
  position: fixed;
  right: 1em;
  bottom: 3em;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*scroll down img*/

.scroll-downs {
  margin: 10em 0;
  display: flex;
  width: 250px;
  height: 55px;
  align-items: center;
}
.mouse {
  margin-right: 1em;
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid rgb(95, 95, 95);
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: rgb(95, 95, 95);
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

/*=== BreakPoints===*/
/*for larger devices*/
@media (max-width: 1150px) {
  .button--flex-up {
    display: none;
  }
}

@media (max-width: 992px) {
  .home__content {
    display: grid;
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }
}
/*for medium devices*/
@media (max-width: 768px) {
  .home {
    margin-top: 3em;
  }
  .home__content {
    grid-template-columns: 100px 200px;
    justify-content: center;
    grid-template-areas:
      "topLeft topRight"
      "bottom bottom";
    padding-top: 0;
  }
  .home__social {
    grid-area: topLeft;
  }
  .home__img {
    grid-area: topRight;
  }
  .home__data {
    grid-area: bottom;
  }
  .scroll-downs {
    margin: 1em auto;
  }
}
@media (max-width: 576px) {
  .home__content {
    grid-template-columns: 50px 200px;
    justify-content: center;
  }
  .home__img {
    width: 200px;
  }
  .home__data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home__description {
    letter-spacing: 4px;
    text-align: left;
  }
  .scroll-downs {
    display: flex;
    width: 250px;
    height: 55px;
    align-items: center;
    justify-content: center;
  }
}
/*for small devices*/
@media (max-width: 350px) {
  .home__content {
    display: flex;
    flex-direction: column;
  }
}

/*animations*/

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
