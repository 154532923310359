.contact__container-title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-1-5);
  box-shadow: inset 0 0 0 0 var(--text-color);
  color: var(--text-color);
  padding: 0 0.25rem;
  margin: 0 -0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}
.contact__container-title:hover {
  color: #fff;
  box-shadow: inset 500px 0 0 0 var(--text-color);
}

.form__container {
  display: flex;
  justify-content: space-around;
}
.left__containers {
  margin: 1em 0;
  padding: 2em 4em;

  border-radius: 15px;
  background: rgba(156, 152, 152, 0.04);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.3px);
  -webkit-backdrop-filter: blur(6.3px);
  border: 1px solid rgba(156, 152, 152, 0.06);
}
.left__containers-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left__containers-text p {
  cursor: pointer;
}

/*input style*/
.form-container {
  display: flex;
  flex-direction: column;
}
.input-el-container {
  margin-bottom: 2em;
  align-self: left;
}
.input-text {
  padding: 1em;
  border: 1px solid var(--text-color);
  border-radius: 15px;
  width: 300px;
}
.input-title {
  position: relative;
  top: 0.7em;
  left: 1em;
  background-color: #fff;
}
.input-title-pro {
  position: relative;
  top: 0.7em;
  left: 1em;
  background-color: #fff;
}
.sub-btn {
  width: 200px;
  padding: 1em 1.5em;
  border-radius: 15px;
  border: 1px solid var(--text-color);
  color: #fff;
  background: var(--title-color-dark);
}

/*media*/

@media (max-width: 992px) {
}
@media (max-width: 860px) {
  .left__containers {
    padding: 1em 1.5em;
  }
  .input-text {
    width: 200px;
  }
}

@media (max-width: 425px) {
  .form__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .left__containers {
    padding: 2em 4em;
  }
  .input-text {
    width: 300px;
  }

  .sub-btn {
    margin-bottom: 5em;
  }
}
/*aux*/

.big-size {
  font-size: 1.3rem;
}
