.about__container {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
}
.about__container-title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-1-5);
  box-shadow: inset 0 0 0 0 var(--text-color);
  color: var(--text-color);
  padding: 0 0.25rem;
  margin: 0 -0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}
.about__container-title:hover {
  color: #fff;
  box-shadow: inset 250px 0 0 0 var(--text-color);
}
p {
  text-align: justify;
  line-height: 2;
}

.about__traits {
  display: flex;
  margin: 4em 1em 2em 1em;
  justify-content: space-evenly;
  column-gap: 5rem;
}

.about__traits-img {
  max-width: 250px;
  border-radius: 15px;
}
.about__traits-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.traits__boxes-container {
  display: flex;
}
.traits__boxes {
  margin: 0 1em;
  padding: 1em 1.5em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background: rgba(156, 152, 152, 0.04);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.3px);
  -webkit-backdrop-filter: blur(6.3px);
  border: 1px solid rgba(156, 152, 152, 0.06);
}

.dark,
.boxes-title {
  font-weight: var(--font-medium);
  color: var(--title-color-dark);
}

.boxes-subtitle {
  font-size: var(--smaller-font-size);
}

.trait__boxes-description {
  margin: 1em;
}
.button--flex {
  margin-left: 1em;
}
.button--flex i {
  margin-left: 1em;
}
/*=== BreakPoints===*/
/*for larger devices*/

@media (max-width: 992px) {
}
/*for medium devices*/
@media (max-width: 768px) {
  .about__traits-img {
    max-width: 200px;
    border-radius: 15px;
  }
  .about__traits {
    column-gap: 1rem;
  }
  p {
    text-align: justify;
  }
}
@media (max-width: 576px) {
  .about__traits {
    flex-direction: column;
    align-items: center;
  }
  .traits__boxes {
    margin: 2em 0.5em 1em 0.5em;
    padding: 0.3em 0.5em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 10px;
  }
  .about__traits-description {
    align-items: center;
  }
}
@media (max-width: 425px) {
  .traits__boxes {
    margin: 2em 0.1em 1em 0.1em;
  }
}
/*for small devices*/
@media (max-width: 350px) {
  .traits__boxes-container {
    display: flex;
    flex-direction: column;
  }
}
