.qualifications__container-title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-1-5);
  box-shadow: inset 0 0 0 0 var(--text-color);
  color: var(--text-color);
  padding: 0 0.25rem;
  margin: 0 -0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}
.qualifications__container-title:hover {
  color: #fff;
  box-shadow: inset 400px 0 0 0 var(--text-color);
}
.qualification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-choice-container {
  display: flex;
}
.info__choice {
  display: flex;
  align-items: center;
  margin: 1em;
  cursor: pointer;
}

.cv-container {
  max-width: 500px;

  margin: 2em auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto 1fr auto;
  grid-template-areas:
    "left1 center1 right1"
    "left2 center2 right2"
    "left3 center3 right3"
    "left4 center4 right4"
    "left5 center5 right5";
  align-items: center;
}
.cv-tabs {
  margin: 0.5em 0.3em;
  height: 200px;
}
.l1 {
  grid-area: left1;
}

.l1::before,
.l2::before,
.l3::before,
.l4::before,
.l5::before {
  content: "";
  position: absolute;
  left: 47%;
  margin-top: 0.3em;
  width: 15px;
  height: 15px;
  background: var(--text-color);
  border-radius: 50%;
}

.l1::after {
  content: "";
  position: absolute;
  left: 47.5%;
  margin-top: -11em;
  width: 1px;
  height: 180px;
  background: rgba(146, 102, 102, 0.137);
}
.l2::after {
  content: "";
  position: absolute;
  left: 47.5%;
  margin-top: -7em;
  width: 1px;
  height: 180px;
  background: rgba(146, 102, 102, 0.137);
}
.l3::after {
  content: "";
  position: absolute;
  left: 47.5%;
  margin-top: -9em;
  width: 1px;
  height: 180px;
  background: rgba(146, 102, 102, 0.137);
}
.l4::after {
  content: "";
  position: absolute;
  left: 47.5%;
  margin-top: -10.5em;
  width: 1px;
  height: 180px;
  background: rgba(146, 102, 102, 0.137);
}

.l2 {
  grid-area: right2;
}

.l3 {
  grid-area: left3;
}
.l4 {
  grid-area: right4;
}
.l5 {
  grid-area: left5;
}
/*aux*/
.big-font {
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: 0.5em;
}
.last-element::after {
  grid-area: right2;
  display: none;
}
/*media */

@media (max-width: 500px) {
  .l1::after,
  .l2::after,
  .l3::after,
  .l4::after {
    display: none;
  }

  .l1::before,
  .l2::before,
  .l3::before,
  .l4::before,
  .l5::before {
    left: 40%;
  }
}
@media (max-width: 425px) {
  .l1::before,
  .l2::before,
  .l3::before,
  .l4::before,
  .l5::before {
    width: 10px;
    height: 10px;
    left: 5%;
  }
  .cv-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .cv-tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1em 2em;
    padding-left: 0.5em;
    border-left: 2px solid var(--text-color);
  }
  .cv-tabs::before {
    display: none;
  }
}
