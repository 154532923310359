.services__container {
  margin-top: 2em;
}
.services__container-title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-1-5);
  box-shadow: inset 0 0 0 0 var(--text-color);
  color: var(--text-color);
  padding: 0 0.25rem;
  margin: 0 -0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}
.services__container-title:hover {
  color: #fff;
  box-shadow: inset 350px 0 0 0 var(--text-color);
}
.service-container-boxes {
  display: flex;
  justify-content: space-between;
}

.service {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  padding: 1em 3em;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  background: rgba(156, 152, 152, 0.04);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.3px);
  -webkit-backdrop-filter: blur(6.3px);
  border: 1px solid rgba(156, 152, 152, 0.06);
}

.view-more {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

/*aux*/

.big-font {
  font-size: 3.5rem;
  font-weight: var(--font-medium);
  margin: 2em 0 0.5em 0;
}

.margin-right {
  margin-right: 0.5em;
}
.arrow-animation {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.8s ease-in-out infinite both;
  font-size: 1.5rem;
}
/*overlay + textbox*/

.overlay-textBox {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(27, 27, 27, 0.185);
  z-index: var(--z-modal);
  overflow: hidden;
}
.text-box {
  position: fixed;
  background: var(--container-color);
  width: 80%;
  left: 10%;
  top: 30vh;
  border-radius: 10px;
  padding: 3em 1em 1em 2em;
  display: flex;
  flex-direction: column;
}
.close-icon {
  font-size: 2rem;
  position: absolute;
  right: 1em;
  top: 0.2em;
}
.aproved-icon {
  margin-right: 0.5em;
}
.list-element {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.text-box-intro {
  margin: 1em 0;
}
.text-box-intro h3 {
  margin-bottom: 1em;
}
/*=== BreakPoints===*/
@media (min-width: 992px) {
  .text-box {
    width: 50%;
    left: 25%;
  }
}

@media (max-width: 992px) {
  .service {
    padding: 1em;
  }
  .big-font {
    margin: 0.5em 0;
  }
}
@media (max-width: 860px) {
  .service-container-boxes {
    flex-direction: column;
    align-items: center;
  }
  .service {
    width: 80%;
    align-items: center;
  }
}

@media (max-width: 425px) {
  .text-box {
    position: absolute;
    top: 10px;
    bottom: 10px;
    align-items: center;
    justify-content: space-evenly;
  }
}

/*animations*/

/* ----------------------------------------------
 * Generated by Animista on 2022-9-26 14:56:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
