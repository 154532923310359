.App {
  text-align: center;
}
/* GOOGLE FONTS*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*VARIABLES*/
:root {
  --header-height: 3rem;

  /*=====Color HSL=====*/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 30%);
  --border-color: hsl(var(--hue), var(--sat), 30%);
  --title-color-dark: hsl(var(--hue), var(--sat), 2%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /*=== Font & Typo===*/

  --body-font: "Poppins", sans-serif;
  /*=== .5rem = 8px | 1rem =16px ===*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.5rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*===Font weight===*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*=== Margins Bottom===*/

  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*=== z -index===*/

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/*===Responsive typo===*/

@media (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --smal-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*===base===*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}
body {
  background-color: var(--body-color);
  color: var(--text-color);
}
h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
  border: none;
  outline: none;
}
img {
  max-width: 100%;
  height: auto;
}

/*===Reusable Classes===*/
.section {
  padding: 6rem 0 2rem;
}
.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}
.section__subtitle {
  display: block;
  font-size: var(--smal-font-size);
  margin-bottom: 4rem;
}
.section__title,
.section__subtitle {
  text-align: center;
}

/*===Layout===*/
.container {
  max-width: 968px;
  margin: 0 auto;
}
.grid {
  display: grid;
  gap: 1.5rem;
}
/*=== BreakPoints===*/
/*for larger devices*/

@media (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
}

/*for medium devices*/
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}
/*for small devices*/
@media (max-width: 350px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}

/*===Buttons===*/
.button {
  display: inline-block;
  background: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}
.button:hover {
  background: var(--title-color-dark);
}
.button__icon {
  margin-left: var(--mb-0-5);
}
.button--flex {
  display: inline-flex;
  align-items: center;
}
