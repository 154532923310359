/*modal when message sent style*/

.overlay-textBox {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(27, 27, 27, 0.185);
  z-index: var(--z-modal);
  overflow: hidden;
}
.text-box {
  position: fixed;
  background: var(--container-color);
  width: 50%;
  left: 20%;
  top: 30vh;
  border-radius: 10px;
  padding: 3em 1em 1em 2em;
  display: flex;
  flex-direction: column;
}
.close-icon {
  font-size: 2rem;
  position: absolute;
  right: 1em;
  top: 0.2em;
}
.text-box-intro {
  margin: 1em 0;
}
