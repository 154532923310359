.skills__container {
  margin-top: 2em;
}
.skills__container-title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-1-5);
  box-shadow: inset 0 0 0 0 var(--text-color);
  color: var(--text-color);
  padding: 0 0.25rem;
  margin: 0 -0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}
.skills__container-title:hover {
  color: #fff;
  box-shadow: inset 250px 0 0 0 var(--text-color);
}

.skillset-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.skill-box {
  width: 400px;
  height: 350px;
  margin: 2em 0;
  padding: 1em 2em;

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: rgba(156, 152, 152, 0.04);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.3px);
  -webkit-backdrop-filter: blur(6.3px);
  border: 1px solid rgba(156, 152, 152, 0.06);
}
.skill-container {
  display: flex;
  margin: 1em 0.5em 1em 1em;
}
.box-flex {
  display: grid;
  grid-template-columns: 150px repeat(1, 1fr);
  justify-items: start;
}
/*aux*/
.fz-big {
  font-size: 1.3rem;
  margin-right: 0.5rem;
}
/*=== BreakPoints===*/

@media (max-width: 860px) {
  .skillset-container {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 425px) {
  .skill-box {
    width: 300px;
    padding: 1em 0.5em;
  }
  .box-flex {
    grid-template-columns: 140px repeat(1, 1fr);
    justify-items: start;
  }
}
