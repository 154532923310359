/*modal to send whatsapp style*/

.overlay-textBox {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(27, 27, 27, 0.185);
  z-index: var(--z-modal);
  overflow: hidden;
}
.text-box {
  position: fixed;
  background: var(--container-color);
  width: 30%;
  left: 35%;
  top: 30vh;
  border-radius: 10px;
  padding: 3em 1em 1em 2em;
  display: flex;
  flex-direction: column;

  align-items: center;
}
.close-icon {
  font-size: 2rem;
  position: absolute;
  right: 1em;
  top: 0.2em;
}
.text-box-intro {
  margin: 1em 0;
}
.text-box-intro h3,
.text-box-intro img {
  margin-bottom: 1em;
}
@media (max-width: 700px) {
  .overlay-textBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-box {
    width: 300px;
    position: unset;
  }
}
